.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 24vh;
}

.login-input {
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    padding: 18px 20px;
    font-size: 16px;
    width: 340px;
    box-sizing: border-box;
    background-color: #f7f7f7;
}

.login-input:focus {
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}

.login-submit {
    background-color: #2c7cb0;
    padding: 8px 0;
    width: 340px;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.login-submit:hover {
    background-color: #2473b7;
}

@media screen and (max-width: 650px) {

    .login-input,
    .login-submit {
        width: 100%;
    }
}