body {
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
}

.card {
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 80vw;
    margin: 0 auto;
    margin-top: calc(50vh - 20px - 12vh);
}

.card-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.card-footer {
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 600px) {
    .card {
        width: 400px;
    }
}